* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

h4 {
  font-weight: bold;
  color: rgb(221, 102, 58);
}

.main .active {
  background-color: rgb(137, 150, 194);
  color: white;
}

html {
  scroll-behavior: smooth;
}
